import { NgModule } from '@angular/core';

import { NgxPaypalComponent } from './components/paypal-component';

@NgModule({
  imports: [
  ],
  declarations: [
    NgxPaypalComponent,
  ],
  exports: [
    NgxPaypalComponent,
  ]
})
export class NgxPayPalModule {
}


