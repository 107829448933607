import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[acAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  private _el: any;

  constructor(private _elementRef: ElementRef) {
    this._el = this._elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this._el.focus();
  }
}
