import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: '[acCollapse]',
  template: `<ng-content></ng-content>`,
  animations: [
    trigger('collapse', [
      state('0', style({ height: '0', opacity: '0', overflow: 'auto' })),
      state('1', style({ height: '*', opacity: '1' })),
      transition('0 => 1', animate('250ms ease-in')),
      transition('1 => 0', animate('250ms ease-out'))
    ])
  ]
})
export class CollapseComponent implements OnInit {
  @HostBinding('@collapse')
  @Input()
  acCollapse: boolean;

  constructor() {}

  ngOnInit() {}
}
