import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpEvent} from "@angular/common/http/src/response";
import {Observable} from "rxjs";

@Injectable()
export class UrlHttpClientService {
  private _base_api: string;

  constructor(
    private _httpClient: HttpClient
  ) {}

  get<T>(resourceUrl: string, options = {}): Observable<T> {
    return this._httpClient.get<T>(this._getFullResourceUrl(resourceUrl), options);
  }

  // noinspection JSUnusedGlobalSymbols
  delete<T>(resourceUrl: string, options?): Observable<HttpEvent<T>> {
    return this._httpClient.delete<T>(this._getFullResourceUrl(resourceUrl), options);
  }

  options<T>(resourceUrl: string, options?): Observable<HttpEvent<T>> {
    return this._httpClient.options<T>(this._getFullResourceUrl(resourceUrl), options);
  }

  patch<T>(resourceUrl: string, body: any | null, options = {}): Observable<T> {
    return this._httpClient.patch<T>(this._getFullResourceUrl(resourceUrl), body, options);
  }

  post<T>(resourceUrl: string, body: any | null, options = {}): Observable<T> {
    return this._httpClient.post<T>(this._getFullResourceUrl(resourceUrl), body, options);
  }

  update<T>(resourceUrl: string, body: any | null, options = {}): Observable<T> {
    return this._httpClient.put<T>(this._getFullResourceUrl(resourceUrl), body, options);
  }

  setBaseAPI(baseAPI): void {
    this._base_api = baseAPI;
  }

  /*Private Methods*/
  private _getFullResourceUrl(resourceUrl: string) {
    if (resourceUrl[0] === '/') {
      return this._base_api + resourceUrl;
    } else {
      return this._base_api + '/' + resourceUrl;
    }
  }
}
